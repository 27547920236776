<div [className]="theme?.root + ' bg-no-repeat bg-center bg-cover'" [style]="theme?.bgImage">
  <div class="container {{ theme.container }}" #layout>
    <div
      *ngIf="contentCardTitle"
      class="wysiwyg tab:px-v-spacing max-w-vision mx-auto"
      [innerHTML]="contentCardTitle"
    ></div>

    <div [className]="'section-container flex items-center justify-center ' + flexClass">
      <div
        *ngIf="!!image && !layoutRightContent"
        class="image-section m-auto w-full lg:w-1/2 py-v-spacing xs:p-v-spacing"
      >
        <img
          src="{{ imgixService.buildImgixUrl(imageUrl) }}&fit=max"
          [alt]="image.altText ? image.altText : ''"
          width="498"
          height="358"
          class="w-full object-contain"
          [attr.loading]="useLazyLoad ? 'lazy' : null"
          [attr.preloadImg]="useLazyLoad ? null : ''"
        />
      </div>
      <div *ngIf="layoutRightContent" class="right-content m-auto w-full lg:w-1/2 xs:p-v-spacing py-v-spacing">
        <div class="wysiwyg" [innerHTML]="layoutRightContent"></div>
      </div>
      <div
        class="p-0 {{ !!image || layoutRightContent ? 'lg:w-1/2' : '' }} w-full"
        [ngClass]="{
          'lg:pr-v-spacing': flexClass.includes('flex-row-reverse') && (!!image || isCard),
          'lg:pl-v-spacing': !flexClass.includes('flex-row-reverse') && (!!image || isCard)
        }"
      >
        <div
          class="content-section w-full xs:p-v-spacing py-v-spacing"
          [ngClass]="{
            'tab:px-v-spacing max-w-vision mx-auto': !image && !isCard && !layoutRightContent,
            'flex flex-col lg:flex-row': productPickerContent
          }"
        >
          <div
            [className]="productPickerContent && layoutSplit ? 'lg:w-[60%] lg:mr-v-spacing lg:pr-v-spacing' : 'lg:w-full lg:mr-v-spacing lg:pr-v-spacing'"
          >
            <div *ngIf="layoutTitle" class="wysiwyg !mb-4 layout-title" [innerHTML]="layoutTitle"></div>
            <div *ngIf="data.showTrustpilot" class="my-v-spacing">
              <div class="flex items-center cursor-pointer text-xs xxsm:text-sm xs:text-base" (click)="openTruspilot()">
                <img
                  class="h-[28px]"
                  height="28"
                  width="150"
                  src="https://xgrit-ecom.imgix.net/ace/trustpilot-star-rating.svg?v=1706889351"
                  alt=""
                />
                <span *ngIf="trustpilotVerified" class="mx-2.5"
                  ><span class="font-extrabold">{{ trustpilotTrustScore }}/5</span> Stars</span
                >
                <span *ngIf="trustpilotVerified"
                  ><span class="font-extrabold">{{ trustpilotNumberOfReviews | number }}</span> Reviews</span
                >
              </div>
            </div>
            <div *ngIf="layoutContent" class="wysiwyg" [innerHTML]="layoutContent"></div>
          </div>
          <div
            *ngIf="(buttons && buttons.length > 0) || (dropdown && dropdown.length > 0) || productPickerContent"
            id="buttons-section"
            [ngClass]="[buttonLayout, buttonAlignment, tertiaryLayout, productPickerContent].join(' ')"
            [className]="productPickerContent && layoutSplit ? 'lg:w-[40%] lg:pl-v-spacing' : 'lg:w-full pt-8 flex'"
            [class.pt-8]="layoutContent"
          >
            <!-- Product Picker Component -->
            <product-picker *ngIf="productPickerContent" [data]="productPickerContent"></product-picker>
            <!-- Dropdown Component -->
            <div *ngIf="!productPickerContent && dropdown && dropdown.length > 0" [class.xs:mr-3]="buttons.length > 0">
              <vision-dropdown *ngIf="dropdown && dropdown.length > 0" [dropdownData]="dropdownData"></vision-dropdown>
            </div>
            <ng-template ngFor let-b [ngForOf]="buttons" let-odd="odd" let-last="last" let-first="first">
              <vision-button
                *ngIf="!productPickerContent"
                [style]="'width:' + buttonWidth"
                defaultStyle="primary"
                [data]="b"
                class="{{ getButtonClasses(last) }}"
              ></vision-button>
            </ng-template>
          </div>
        </div>
      </div>
      <div
        *ngIf="isCard"
        class="card-section layout-card w-full min-w-[50%]"
        [ngClass]="{ 'bg-white md:py-0': isCardContent }"
      >
        <div class="flex-col h-full" [ngClass]="{ 'md:flex md:flex-row': isCardContent }">
          <div class="card-section__image flex-3 h-48" [ngClass]="{ 'md:h-auto': isCardContent }">
            <div class="w-full h-full" [ngClass]="{ relative: isCardContent }">
              <div
                class="w-full h-full block bg-no-repeat bg-center bg-cover"
                [style.backgroundImage]="cardImageStyle"
              ></div>
            </div>
          </div>
          <div
            class="card-section__content flex-4"
            [ngClass]="{
              'm-2 -mt-8 bg-white rounded shadow-lg': !isCardContent,
              'py-v-spacing sm:px-v-spacing': cardImageStyle.length === 0,
              'p-v-spacing': cardImageStyle.length > 0
            }"
          >
            <div class="wysiwyg text-black" [innerHTML]="cardContent"></div>
            <div *ngIf="!!cardButtonLabel" class="button-section mt-8">
              <vision-button defaultStyle="tertiary" [data]="cardButtonData"></vision-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showModal" class="layout__modal fixed inset-0 bg-vis-dark/50 z-50 flex justify-center items-center">
      <div
        class="layout__modal-body bg-white text-vis-regular shadow-md px-8 py-v-spacing wysiwyg relative rounded flex"
      >
        <div class="layout__modal-body-content wysiwyg" [innerHTML]="modalContent"></div>
        <fa-icon
          class="layout__modal-close text-vis-muted absolute right-v-spacing top-v-spacing fa-1x"
          (click)="showModal = false"
          [icon]="faTimes"
        ></fa-icon>
      </div>
    </div>
  </div>
</div>
