<div
  [style]="theme?.bgImage"
  [className]="theme?.root + ' mx-auto bg-no-repeat bg-center bg-cover px-px-v-spacing '"
>
  <div
    class="flex {{ useBannerLayout ?? 'items-center' }} max-w-vision {{ theme?.container }} mx-auto px-v-spacing {{
      flexLayout
    }} "
  >
    <img
      *ngIf="hasImage"
      [src]="data?.learnMoreImage[0].url"
      [alt]="data.learnMoreImage[0].altText"
      class="{{ useBannerLayout ? 'md:w-30p' : 'w-full' }} max-w-[200px] md:mr-12 mb-v-spacing md:mb-0 ml-v-spacing"
    />
    <div class="flex {{ flexLayout }} {{ useBannerLayout ? 'md:w-70p items-center' : 'w-full' }}">
      <div
        class="w-full wysiwyg pr-0 md:pr-4 {{ contentLayout }} {{ useBannerLayout && 'md:mr-8' }}"
        [innerHtml]="content"
      ></div>
      <div *ngIf="data.ctaLink" class="md:p-0 w-full {{ buttonLayout }}">
        <div class="cta-container">
          <vision-button [data]="buttonData"></vision-button>
        </div>
      </div>
    </div>
  </div>
</div>
