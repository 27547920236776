import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { ImgixService } from '@core-mkt/services/imgix/imgix.service';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { faArrowLeft, faArrowRight, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import type { CareerCenterArticleCard } from './career-center';
import { CareerCenter } from './career-center';

@Component({
  selector: 'career-center',
  templateUrl: './career-center.component.html',
  styleUrls: ['./career-center.component.scss'],
})
export class CareerCenterComponent implements OnInit {
  @Input() data: CareerCenter;
  activeIndex = 0;
  selectedCategoryFilter = '';
  categoryList: string[] = [];
  pages = [];
  mobilePageIndex = 0;
  deskPageIndex = 0;
  theme: ThemeInterface;
  careerCenterContent = undefined;
  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;
  faEllipsisH = faEllipsisH;
  originalCareerCenterData: CareerCenterArticleCard[];

  constructor(
    private themeParser: ThemeParserService,
    private redactor: WysiwygRedactorService,
    private imgixService: ImgixService,
  ) {}

  get careerCenterSideContent(): string | undefined {
    return this.careerCenterContent;
  }

  get careerCenterTitle(): string | undefined {
    return this.data.careerCenterTitle;
  }

  get filterTitle(): string {
    return this.data.filterTitle ? this.data.filterTitle : 'Filter by Category';
  }

  get filterDefaultLabel(): string {
    return this.data.filterDefaultLabel ? this.data.filterDefaultLabel : 'All Resources';
  }

  get careerCenterArticleCards(): CareerCenterArticleCard[] | undefined {
    return this.data.careerCenterArticleCards;
  }

  ngOnInit(): void {
    this.data.careerCenterTitle = this.redactor.bypassSanitizer(this.data.careerCenterTitle);
    if (this.data?.careerCenterSideContent) {
      this.careerCenterContent = this.redactor.bypassSanitizer(this.data.careerCenterSideContent);
    }

    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      textColor: this.data.textColor,
      columnCount: this.data.careerCenterArticleCards.length,
      columnDistribution: undefined,
      backgroundImage: undefined,
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
    this.originalCareerCenterData = [...this.data.careerCenterArticleCards];
    this.getCategoryList(this.originalCareerCenterData);
    this.getNumberOfPages(this.data.careerCenterArticleCards);
  }

  getCategoryList(careerCenterArticleCards: CareerCenterArticleCard[]): void {
    const tempCategoryList: string[] = [];
    careerCenterArticleCards.forEach((element) => {
      if (element.relatedResourcesCategory) {
        element.relatedResourcesCategory.forEach((category) => {
          tempCategoryList.push(category.title);
        });
      } else if (element.careerCenterArticleCards) {
        element.careerCenterArticleCards.forEach((category) => {
          tempCategoryList.push(category.title);
        });
      }
    });
    this.categoryList = [...new Set(tempCategoryList)];
  }

  onCategoryFilterChange(category: string): void {
    this.selectedCategoryFilter = category;

    if (this.selectedCategoryFilter != '') {
      const filteredPages = this.originalCareerCenterData.filter(
        (object) => object.relatedResourcesCategory[0]?.aceCategoryCode === category,
      );
      this.getNumberOfPages(filteredPages);
    } else {
      this.clearCategoryFilter();
    }
  }

  clearCategoryFilter(): void {
    this.selectedCategoryFilter = '';
    this.getNumberOfPages([...this.originalCareerCenterData]);
  }

  getNumberOfPages(careerCenterArticleCards): void {
    this.goToFirstPage();
    this.pages = [];
    const articleCards = careerCenterArticleCards;
    while (articleCards.length > 0) {
      this.pages.push(articleCards.splice(0, 10));
    }
  }

  changePage(index: number): void {
    if (index % 2 === 0) {
      this.mobilePageIndex = index;
    }
    if (index % 3 === 0) {
      this.deskPageIndex = index;
      this.mobilePageIndex = index - 1;
    }
    this.activeIndex = index;
  }

  goToNextPage(): void {
    if (this.activeIndex < this.pages.length) {
      this.changePage(this.activeIndex + 1);
    }
  }

  goToLastPage(): void {
    this.activeIndex = this.pages.length - 1;
  }

  goToFirstPage(): void {
    this.activeIndex = 0;
  }

  goToPreviousPage(): void {
    if (this.activeIndex > 0) {
      this.changePage(this.activeIndex - 1);
    }
  }

  skip(direction: string): void {
    let skipIdx = this.activeIndex + 2;
    if (direction === 'back') {
      skipIdx = this.activeIndex - 2;
    }
    this.changePage(skipIdx);
  }

  scroll(el: HTMLElement): void {
    el.scrollIntoView({
      behavior: 'auto',
      block: 'start',
      inline: 'start',
    });
  }
}
