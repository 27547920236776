<a href="{{ctaLink}}" rel="noopener noreferrer"
    [attr.aria-labelledby]="getIdToAriaLabelledbyContext(product.cardTitle, ctaLink)"
    (mouseover)="handleMouseOver()"
    (mouseout)="handleMouseOut()"
    class="h-full cursor-pointer rounded-md hover:shadow-xl shadow-card p-6
    justify-start items-center gap-4 inline-flex w-full {{
    theme?.root
  }}">
  <div class="{{ theme?.container }}">
    <div class="flex-grow flex-shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
        <div class="self-stretch card-title" [innerHTML]="product.cardTitle"></div>
        <div class="self-stretch" *ngIf="product.xGritProduct">
            <span>{{priceText}}</span>
            <span class="font-extrabold"> ${{ product.xGritProduct?.apiData?.discountPrice | customNumber: '1.2-2'}}</span>
        </div>
    </div>
    <div class="self-center justify-self-end">
        <a  class="!w-12 h-12 !p-0 transition"
            [ngClass]="hoverClass"
        >
                <fa-icon class="leading-[0]" [icon]="faArrowRight"></fa-icon>
        </a>
    </div>
    <!-- Hidden Context -->
    <span [attr.id]="getIdToAriaLabelledbyContext(product.cardTitle, ctaLink)" class="sr-only">{{getTextContext(ctaLink)}}</span>
  </div>
</a>
